<template>
	<div class="mb-3 locations-contacts card">
		<div class="row g-0">
			<h2 class="mb-1 card-title">{{ header }}</h2>
			<h6 class="card-subheader color-secondary">{{ subheader }}</h6>
			<div class="col-md-4">
				<img v-if="image"
					:src="image[0].publicUrl"
					:alt="image[0].properties.alternative"
					class="image--cover"
				/>
			</div>
			<div class="col-md-8">
				<div class="card-body">
					<header class="card-person-name">
						{{ locationContactPersonName }}
					</header>
					<span class="card-adress">
						{{ locationContactPersonAdress }}
					</span>
					<span class="card-icon-phone">
						<font-awesome-icon
							:icon="['fal', 'phone']"
						/>
						{{ locationContactPersonPhone }}
					</span>
					<span class="card-icon-email">
						<font-awesome-icon
							:icon="['fal', 'envelope']"
						/>
						{{ locationContactPersonEmail }}
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'LocationsContacts',
	props: {
		header: {
			type: String
		},
		subheader: {
			type: String
		},
		image: {
			type: Array
		},
		locationContactPersonAdress: {
			type: String
		},
		locationContactPersonName: {
			type: String
		},
		locationContactPersonPhone: {
			type: String
		},
		locationContactPersonEmail: {
			type: String
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/common';
.locations-contacts {

	&.card  {
		background-color: transparent !important;
		border: 0 !important;

		.card-title, .card-subheader {
			text-transform: none;
		}
		.card-subheader {
			@include responsive-type(2, 1.0);
			color: $primary-light;
			font-weight:  $font-weight-bold;
		}

		.image--cover {
			width: 150px;
			height: 150px;
			border-radius: 50%;

			object-fit: cover;
			object-position: center right;
		}

		.card-body {
			display: grid;

			.card-person-name {
				@include responsive-type(3, 1.0);
				color: $primary;
				margin-bottom: rem(20);
			}

			.card-adress {
				margin-bottom: rem(20);
			}

			.card-icon-phone,
			.card-icon-email {
				color: $secondary;
				@include responsive-type(2, 1.8);

				.svg-inline--fa {
					color: $primary;
					margin-right: rem(10)
				}
			}
		}
	}
}
</style>
