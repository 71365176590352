<template>
	<div>
		<gmap-map
			ref="mapRef"
			class="vue-map-container"
			:center="GeoLocation"
			:zoom="18"
			:options="{
				streetViewControl: false,
				rotateControl: false,
				scrollwheel: false,
				zoomControl: false
			}"
		>
			<gmap-custom-marker
				v-for="(marker, index) in markers"
				:key="index"
				:marker="marker.position"
			>
				<font-awesome-icon
					class="gmap-custom-marker"
					:icon="[
						'fas',
						'map-marker'
					]"
				/>
			</gmap-custom-marker>
		</gmap-map>
	</div>
</template>

<script>
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import {staticMarkers} from '../map/PageFooterStaticMarkers';

export default {
	name: 'LocationGoogleMaps',
		components: {
		'gmap-custom-marker': GmapCustomMarker
  	},
	data () {
		return {
			markers: staticMarkers,
			place: null,
			GeoLocation : {
				lat: this.$store.getters.locationLatitude,
				lng: this.$store.getters.locationLongitude
			},
		}
	},
	methods: {
		setDescription (description) {
			this.description = description;
		},
		setPlace (place) {
			this.place = place;

			this.usePlace();
		},
		usePlace () {
			if (this.place) {
				this.$refs.mapRef.$mapPromise.then((map) => {
					// window.map = map;
					map.fitBounds(this.place.geometry.viewport);
					map.panToBounds(this.place.geometry.viewport);
					// Increase the zoom level so we have a more detailed view
					map.setZoom(map.getZoom() + 1);
				});
			}
		}
	}
}
</script>

