var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('gmap-map',{ref:"mapRef",staticClass:"vue-map-container",attrs:{"center":_vm.GeoLocation,"zoom":18,"options":{
			streetViewControl: false,
			rotateControl: false,
			scrollwheel: false,
			zoomControl: false
		}}},_vm._l((_vm.markers),function(marker,index){return _c('gmap-custom-marker',{key:index,attrs:{"marker":marker.position}},[_c('font-awesome-icon',{staticClass:"gmap-custom-marker",attrs:{"icon":[
					'fas',
					'map-marker'
				]}})],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }