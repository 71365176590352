<template>

	<div class="applicant-form">
		<div class="pb-2">

			<FormulateForm
				class="row gy-1 gx-4"
				v-model="formValues"
				@submit="submitHandler"
			>
				<FormulateInput
					name="salutation"
					type="select"
					label="Anrede"
					placeholder="Anrede"
					class="col-md-12"
					:options="{frau: 'Frau', herr: 'Herr', sonstiges: 'divers'}"
				/>

				<FormulateInput
					name="givenName"
					type="text"
					label="Vorname"
					placeholder="Vorname"
					class="col-md-6"
				/>

				<FormulateInput
					name="surname"
					type="text"
					label="Nachname*"
					placeholder="Nachname"
					class="col-md-6"
					validation="required"
				/>

				<FormulateInput
					name="zip"
					type="text"
					label="PLZ*"
					placeholder="PLZ"
					class="col-md-2"
					validation="required"
				/>

				<FormulateInput
					name="location"
					type="text"
					label="Wohnort*"
					placeholder="Wohnort"
					class="col-md-4"
					validation="required"
				/>

				<FormulateInput
					name="mail"
					type="email"
					label="E-Mail*"
					placeholder="E-Mail"
					class="col-md-3"
					validation="required"
				/>

				<FormulateInput
					name="telephone"
					type="text"
					label="Telefonnummer"
					placeholder="Telefonnummer"
					class="col-md-3"
				/>

				<FormulateInput
					name="message"
					type="textarea"
					label="Ihre Nachricht"
					placeholder="Ihre Nachricht"
					class="col-md-12"
				/>

				<FormulateInput
					name="datenschutzbestimmung"
					type="checkbox"
					validation="required"
				>
					<template #label="{ id }">
						<label :for="id">
							<span class="ps-2">Ich bin damit einverstanden, dass der ISD Immobilien Service Deutschland meine <nuxt-link to="/datenschutz.html" title="Zur Datenschutzerklärung" target="_blank">Daten</nuxt-link> speichert und mich kontaktiert.</span>
						</label>
					</template>
				</FormulateInput>

				<FormulateInput
					type="submit"
				>
					<span
						class="formulate-input-element--submit--label"
						id="button-kontaktformular-senden"
					>
						Abschicken
					</span>
				</FormulateInput>

			 </FormulateForm>
		</div>
	</div>

</template>

<script>
import { mapState } from 'vuex';

export default {
	data() {
		return {
			date: new Date(),
			formValues: {},
			country: [
				{
					value: 'deutschland',
					id: 'deutschland',
					label: 'Deutschland'
				},
				{
					value: 'belgien',
					id: 'belgien',
					label: 'Belgien'
				},
				{
					value: 'daenemark',
					id: 'daenemark',
					label: 'Dänemark'
				},
				{
					value: 'frankreich',
					id: 'frankreich',
					label: 'Frankreich'
				},
				{
					value: 'grossbritannien',
					id: 'grossbritannien',
					label: 'Grossbritannien'
				},
				{
					value: 'italien',
					id: 'italien',
					label: 'Italien'
				},
				{
					value: 'luxemburg',
					id: 'luxemburg',
					label: 'Luxemburg'
				},
				{
					value: 'niederlande',
					id: 'niederlande',
					label: 'Niederlande'
				},
				{
					value: 'oesterreich',
					id: 'oesterreich',
					label: 'Österreich'
				},
				{
					value: 'polen',
					id: 'polen',
					label: 'Polen'
				},
				{
					value: 'portugal',
					id: 'portugal',
					label: 'Portugal'
				},
				{
					value: 'schweiz',
					id: 'schweiz',
					label: 'Schweiz'
				},
				{
					value: 'spanien',
					id: 'spanien',
					label: 'Spanien'
				},
				{
					value: 'tuerkei',
					id: 'tuerkei',
					label: 'Türkei'
				},
    		],
		}
	},

	computed: {
		...mapState([
			'subsidiary'
		])
	},

	methods: {
		async submitHandler() {
			const data = {
				salutation: this.formValues.salutation ? this.formValues.salutation.label : '',
				givenName: this.formValues.givenName,
				surname: this.formValues.surname,
				message: this.formValues.message,
				mail: this.formValues.mail,
				telephone: this.formValues.phone,
				zip: this.formValues.zip,
				location: this.formValues.location,
			}

			try {
				await this.$api.contact.send(data);
				window.location.href = `/danke.html`;
			} catch (error) {
				this.$toast('Es ist ein Fehler aufgetreten: ' + (error.message || 'Unbekannter Fehler'), {
					timeout: 2000
				});
				console.log(error);
			}
		},

		getClosestDivision() {
			const goal = parseFloat(this.formValues.zip)
			const closestDivision = this.subsidiary.reduce(function(prev, curr) {
				const currZip = parseFloat(curr.plz);
				const prevZip = parseFloat(prev.plz);
				return (Math.abs(currZip - goal) < Math.abs(prevZip - goal) ? curr : prev);
			});

			return closestDivision;
		},
	},
	mounted () {
		this.$gtm.push({ event: 'KontaktFormularAbsenden'});
	},
}
</script>
<style lang="scss">
	@import '~@/assets/scss/common';
	.applicant-form {
		.formulate-input[data-classification='group']
		.formulate-input-group-item {
			padding-left: 1rem;
		}
		.formulate-input[data-classification='box']
		.formulate-input-label {
			font-weight: 300;
			cursor: pointer;
		}
		.formulate-input[data-classification='box'] .formulate-input-element {
			@include media-breakpoint-down(md) {
				width: 30px;
			}
		}
		.vdp-datepicker__calendar .cell.selected {
			background: $isd--blue-dark;
			color: #fff;
		}
		@include media-breakpoint-down(md) {
			span {
				display: block;
				font-size: 1rem;
			}
		}
	}
</style>
