<template>
	<div class="col">
		<div class="counter sm-mb-5 md-mb-10">

			<div class="counter-icon">
				<font-awesome-icon
					:icon="['isd',`${icon}`]"
				/>
			</div>

			<div class="counter-count-body">

				<div class="counter-count">
					<ICountUp
						:endVal="counterValue"
						:options="options"
						v-if='inViewport.now'
					/>
				</div>

				<div class="counter-post">
					{{header}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ICountUp from 'vue-countup-v2';
	import inViewport from 'vue-in-viewport-mixin';
	export default {
		name: 'Counter',
		components: {
			ICountUp
		},
		props: {
			counterValue: {
				type: Number,
				default: 0
			},
			header: {
				type: String
			},
			icon: {
				type: String
			}
		},
		data() {
			return {
				delay: 1000,
				options: {
					useEasing: true,
					useGrouping: true,
					separator: '.',
					decimal: ',',
					prefix: '',
					suffix: ''
				}
			};
		},
		 mixins: [ inViewport ]
	};
</script>

<style lang="scss">
	@import '~@/assets/scss/common';
	$counter--counter-width: rem(160) !default;
	$counter--counter-margin: rem(160) !default;
	$counter--counter-width-mobile: rem(130) !default;
	$counter--counter-icon-size: rem(60) !default;
	$counter--counter-count-font-size: rem(80) !default;
	$counter--counter-count-font-size-mobile: rem(50) !default;
	$counter--counter-count-post-font-size: rem(30) !default;
	$counter--counter-count-post-font-size-mobile: rem(20) !default;
	$counter--counter-count-color: color(primary, base) !default;
	$counter--counter-count-post-color: color(primary, light) !default;
	$counter--counter-before-size: 70px !default;
	$counter--counter-before-size-mobile: 50px !default;
	$counter--counter-before: 10px !default;
	$counter--counter-before-color: #D6EAF9 !default;
	$counter--counter-border-color: color(primary, base) !default;

	.counter {
		position: relative;
		display: flex;

		@include media-breakpoint-up(md) {
			display: block;
			text-align: center;
		}
	}

	.counter .counter-count-body {
		display: flex;
		flex-direction: column;

		@include media-breakpoint-down(sm) {
			width: 10%;
		}
	}

	.counter .counter-icon {
		position: relative;
		width: $counter--counter-width-mobile;
		height: $counter--counter-width-mobile;
		margin: 0 30px 55px 0 ;

		@include media-breakpoint-up(md) {
			width: $counter--counter-width;
			height: $counter--counter-width;
			margin: 0 auto;
			margin-bottom: 55px;
		}

		color: $counter--counter-border-color;
		display: flex;
		align-items: center;
		border: 1px solid $counter--counter-border-color;
		border-radius: 100%;


		.svg-inline--fa {
			font-size: $counter--counter-icon-size;
			margin: 0 auto;
		}
	}

	.counter .counter-icon {

		&:before {
			content: "";
			position: absolute;
			background: $counter--counter-before-color;
			width: $counter--counter-before-size-mobile;
			height: $counter--counter-before-size-mobile;

			@include media-breakpoint-up(md) {
				width: $counter--counter-before-size;
				height: $counter--counter-before-size;
			}

			top: 0;
			right: 0;
			border-radius: 100%;
			margin-top: -$counter--counter-before;
			margin-right: -$counter--counter-before;
			z-index: -1;
		}
	}

	.counter-count {
		font-size: $counter--counter-count-font-size-mobile;
		color: $counter--counter-count-color;
		line-height: $counter--counter-count-font-size;
		font-weight: 800;

		@include media-breakpoint-up(md) {
			font-size: $counter--counter-count-font-size;
		}

		@include media-breakpoint-down(lg) {
			font-size: $counter--counter-count-font-size / 2;
		}

	}
	.counter-post {

		font-size: $counter--counter-count-post-font-size-mobile;
		color: $counter--counter-count-post-color;
		font-weight: 800;

		@include media-breakpoint-up(md) {
			font-size: $counter--counter-count-post-font-size;
		}
	}
</style>
